<template>
  <div class="table-responsive">
    <table class="table table-sm table-bordered table-striped" id="role_list">
      <thead>
      <tr>
        <th>#</th>
        <th>Role Name</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(role, index) in roles" :key="index">
        <td>{{ index + 1 }}</td>
        <td>{{ role.role_name }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import $ from 'jquery'

export default {
  name: 'List',
  data() {
    return {
      roles: [],
    }
  },
  mounted() {
    this.getRoles()
  },
  methods: {
    getRoles() {
      apiClient.get('api/roles/list')
        .then(response => {
          this.roles = response.data.roles
          setTimeout(function() {
            $('#role_list').DataTable(
              {
                pagingType: 'full_numbers',
                pageLength: 10,
                processing: true,
                dom: 'Blfrtip',
                buttons: ['copy', 'csv', 'print'],
              },
            )
          }, 1000,
          )
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>
<style scoped>

</style>
